import styled from 'styled-components';
import Button from 'components/Button';
import {
    BRAND_LIGHTER, DIVIDER, WHITE, BUTTON_SHADOW,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    max-height: 100vh;

    @media (max-width: 800px) {
        height: 100%;
        min-height: 100%;
    }
`;

export const Header = styled.div`
    max-width: 460px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 0 1.5rem;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    max-width: 460px;
    width: 100%;
    flex: 0;
    padding: 0rem 1.5rem 1rem;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    z-index: 3;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 460px;
    width: 100%;
    flex: 1;
    overflow: auto;
    padding: 0rem 0rem 4rem 1.5rem;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    color: ${WHITE};
    box-shadow: ${BUTTON_SHADOW};

    &[disabled] {
        background: ${BRAND_LIGHTER};
        color: ${WHITE};
        box-shadow: none; !important;
    }

    &:hover {
        color: ${WHITE};
    }
`;

export const ButtonLabel = styled.span`
    width: 100%;
    padding-left: 1.4rem;
`;

export const EmptyMessage = styled.p``;

export const DateSplitterContainer = styled.div<{ isSticky?: boolean }>`
    display: flex;
    align-items: center;
    align-self: stretch;
    background: ${WHITE};
    position: sticky;
    top: -1px;
    z-index: 2;
    border-bottom: 1px solid ${DIVIDER};

    padding: 1.25rem 0.75rem 0.5rem 0rem;
    align-items: center;
    align-self: stretch;

    &:first-child {
        &::before, 
        &::after {
            content: '';
            flex: unset;
            border-bottom: unset;
        }
    }
`;

export const DateSplitterLabel = styled.span`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem;
`;

export const Divider = styled.div`
    display: flex;
    min-height: 1px;
    max-height: 1px;
    align-self: stretch;
    background-color: ${DIVIDER};
    margin: 0;
`;
