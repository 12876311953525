import styled, { css } from 'styled-components';
import Button from '../../components/Button';
import {
    BRAND, BRAND_LIGHTER, WHITE, BUTTON_SHADOW,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;

    @media (max-width: 800px) {
        height: 100%;
        min-height: 100vh;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 460px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    padding: 1rem 2rem 5rem 2rem;
    gap: 40px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
    flex-grow: 1;
`;

export const StyledButton = styled(Button)`
    width: 100%;

    &[disabled] {
        box-shadow: none;
    }

    ${(props) => props.variant === 'brand' && css`
        color: ${WHITE};
        box-shadow: ${BUTTON_SHADOW};

        &[disabled] {
            background: ${BRAND_LIGHTER};
            color: ${WHITE};
        }

        &:hover {
            color: ${WHITE};
        }
    `};
`;

export const TopContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const StyledIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    background-color: ${BRAND_LIGHTER};
    color: ${BRAND};
    border-radius: 0.375rem;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
`;
