import styled from 'styled-components';
import Logo from '../../components/Logo';
import PhoneInput from '../../components/PhoneInput';
import Button from '../../components/Button';
import PageHeader from '../../components/PageHeader';
import {
    BLACK, BRAND_LIGHTER, DARK_GREY, WHITE, BUTTON_SHADOW, GREY,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;

    @media (max-width: 800px) {
        height: 100%;
        min-height: calc(100vh - 2rem);
    }
`;

export const StyledPageHeader = styled(PageHeader)`
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 460px;
    width: 100%;
    padding: 0 2rem;
    gap: 1.625rem;
    flex-grow: 1;
`;

export const StyledPhoneInput = styled(PhoneInput)`
    width: 100%;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    color: ${WHITE};
    box-shadow: ${BUTTON_SHADOW};

    &[disabled] {
        border: 1px solid transparent;
        background: ${BRAND_LIGHTER};
        color: ${WHITE};
        box-shadow: none; !important;
    }

    &:hover {
        color: ${WHITE};
    }
`;

export const ButtonLabel = styled.span`
    width: 100%;
    padding-left: 1.4rem;
`;

export const TopContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
`;

export const PhoneNumber = styled.h3`
    font-size: 0.9375rem;
    font-weight: 700;
    line-height: 1.3125rem;
    color: ${BLACK};
`;

export const Disclaimer = styled.p`
    display: flex;
`;

export const TermsOfServiceContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    color: ${DARK_GREY};
    font-size: 0.85rem;
    font-weight: 300;
    line-height: 1.375rem;
    padding: 1rem 0.5rem;

    a {
        color: ${DARK_GREY};
        border-bottom: 1px solid ${DARK_GREY};

        &:hover {
            color: ${BLACK};
            border-bottom: 1px solid ${BLACK};
            text-decoration: none;

        }
    }
`;

export const StyledLogo = styled(Logo)`
    width: 15.625rem;
    height: 7.5rem;
    align-self: start;
`;

export const TermsAndAgreementContainer = styled.div`
    display: flex;
    align-items: flex-start;
    color: ${GREY};

    & > div:last-child {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem; 
    }

    a {
        color: ${BLACK};
        text-decoration: underline;

        &:hover {
            color: ${BLACK};
        }
    }

    @media (max-width: 768px) {
        font-size: 13px;
    }                      
`;
