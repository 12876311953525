import styled from 'styled-components';
import { Calendar } from 'react-rainbow-components';
import Button from 'components/Button';
import { BRAND_LIGHTER, WHITE, BUTTON_SHADOW } from '../../../constants';

export const StyledCalendar = styled(Calendar)`
    width: 100%;
`;

export const StyledButton = styled(Button)`
    margin-bottom: 1.5rem;
    width: 100%;
    color: ${WHITE};
    box-shadow: ${BUTTON_SHADOW};

    &[disabled] {
        background: ${BRAND_LIGHTER};
        color: ${WHITE};
        box-shadow: none; !important;
    }

    &:hover {
        color: ${WHITE};
    }
`;

export const ButtonLabel = styled.span`
    width: 100%;
    padding-left: 1.4rem;
`;

export const TopContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
