import { IconProps } from 'types';

const UploadIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={72}
        height={73}
        viewBox="0 0 72 73"
        fill="none"
    >
        <title>{title}</title>
        <path d="M20.9999 54.4999C13.2051 54.4999 6.88623 48.4557 6.88623 40.9999C6.88623 33.544 13.2051 27.4999 20.9999 27.4999C22.1908 22.1945 26.4361 17.8639 32.1366 16.1394C37.8372 14.4149 44.1269 15.5585 48.6366 19.1394C53.1463 22.7203 55.1908 28.1945 53.9999 33.4999H56.9999C62.7989 33.4999 67.4999 38.2009 67.4999 43.9999C67.4999 49.7989 62.7989 54.4999 56.9999 54.4999H53.9999" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27 45.5L36 36.5L45 45.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M36 36.5V63.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default UploadIcon;
