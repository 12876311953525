import styled from 'styled-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import Button from 'components/Button';
import {
    BRAND, BRAND_LIGHTER, WHITE, BUTTON_SHADOW,
} from '../../../constants';

export const StyledUniversalPicker = styled(UniversalPicker)`
    width: 100%;
    overflow: auto;
    justify-content: flex-start;
    height: calc(100vh - 30rem);
    
    > div {
        gap: 0.5rem;
        
        > div {
            margin: 0;
        }
    }
`;

export const StyledButton = styled(Button)`
    margin-bottom: 1.5rem;
    width: 100%;
    color: ${WHITE};
    box-shadow: ${BUTTON_SHADOW};

    &[disabled] {
        background: ${BRAND_LIGHTER};
        color: ${WHITE};
        box-shadow: none; !important;
    }

    &:hover {
        color: ${WHITE};
    }
`;

export const ButtonLabel = styled.span`
    width: 100%;
    padding-left: 1.4rem;
`;

export const TopContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

interface StyledRadioItemProps {
    isHover: boolean;
    isFocused: boolean;
    isSelected: boolean;
    disabled: boolean;
}

export const StyledRadioItem = styled.span<StyledRadioItemProps>`
    position: relative;
    width: 100%;
    padding: 0.75rem;
    background-color: white;
    border: solid 1px #dbdade;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${BRAND};
    font-size: 1rem;

    ${(props) => props.isHover
        && `
            background-color: ${BRAND_LIGHTER};    
            border: solid 1px ${BRAND};
            cursor: pointer;
    `};

    ${(props) => props.isFocused
        && `
            border: solid 1px ${BRAND};
            box-shadow: ${BRAND_LIGHTER} 0px 0px 2px;
    `};

    ${(props) => props.isSelected
        && `
            border: solid 1px ${BRAND};
            box-shadow: ${BRAND_LIGHTER} 0px 0px 2px;
    `};

    ${(props) => props.disabled
        && `
            border: solid 1px #dbdade;
            background-color: #dbdade;
            box-shadow: 0 0 0 0 transparent;
            cursor: not-allowed;
    `};
`;
