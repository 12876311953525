import { useEffect, useRef, useState } from 'react';
import { FirebaseError } from 'firebase/app';
import { signInWithPhoneNumber, RecaptchaVerifier, ConfirmationResult } from 'firebase/auth';
import { RenderIf } from 'react-rainbow-components';
import { showAppMessage, hideAppMessage } from '@rainbow-modules/app';
import getFirebaseErrorMessage from 'data/helpers/getFirebaseErrorMessage';
import TopBar from 'components/TopBar';
import Footer from 'components/Footer';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import Input from 'components/Input';
import PageHeader from 'components/PageHeader';
import CodeInput from 'components/CodeInput';
import { auth } from '../../firebase';
import {
    Container,
    FormContainer,
    StyledPhoneInput,
    StyledButton,
    ButtonLabel,
    PhoneNumber,
    TopContent,
    StyledPageHeader,
    StyledLogo,
    TermsAndAgreementContainer,
} from './styled';

const Login = () => {
    const recaptchaVerifier = useRef<any>(null);
    const codeInputRef = useRef(null);
    const [phoneNumber, setPhoneNumber] = useState<any>('');
    const [confirmationResult, setConfirmation] = useState<ConfirmationResult | null>(null);
    const [code, setCode] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [agreementAccepted, setAgreementAccepted] = useState(false);

    const onNext = async () => {
        setIsLoading(true);
        try {
            const results = await signInWithPhoneNumber(
                auth,
                `${phoneNumber.countryCode}${phoneNumber.phone}`,
                recaptchaVerifier.current,
            );
            hideAppMessage();
            setConfirmation(results);
        } catch (error) {
            showAppMessage({
                message: getFirebaseErrorMessage((error as FirebaseError).code),
                variant: 'error',
            });
        }
        setIsLoading(false);
    };

    const onLogin = async () => {
        setIsLoading(true);
        try {
            await confirmationResult?.confirm(code);
            hideAppMessage();
        } catch (error) {
            showAppMessage({
                message: getFirebaseErrorMessage((error as FirebaseError).code),
                variant: 'error',
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (confirmationResult === null) {
            recaptchaVerifier.current = new RecaptchaVerifier('sign-in-button', {
                size: 'invisible',
                callback: async () => {
                },
            }, auth);
        }
    }, [confirmationResult]);

    useEffect(() => {
        if (confirmationResult && codeInputRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            codeInputRef.current?.focus();
        }
    }, [confirmationResult]);

    return (
        <Container>
            <TopBar hideBackButton={!confirmationResult} onBack={() => setConfirmation(null)} />
            <FormContainer>
                <StyledLogo />
                <RenderIf isTrue={!confirmationResult}>
                    <StyledPageHeader
                        title="Welcome to  Affiliate Program! 👋"
                        description="Welcome, valued partner. Ready to schedule a patient walkthrough? Let’s ensure they receive the highest standard of care from the start."
                    />
                    <StyledPhoneInput
                        value={phoneNumber}
                        label="Your Phone Number"
                        placeholder="Type your phone number here..."
                        onChange={setPhoneNumber}
                        borderRadius="semi-square"
                        countries={['us']}
                        labelAlignment="left"
                        required
                    />
                    <StyledButton
                        borderRadius="semi-square"
                        size="large"
                        variant="brand"
                        id="sign-in-button"
                        onClick={onNext}
                        isLoading={isLoading}
                        disabled={!phoneNumber || !phoneNumber.phone || !agreementAccepted}
                    >
                        <ButtonLabel>
                            Continue
                        </ButtonLabel>
                        <ArrowNarrowRight />
                    </StyledButton>
                    <TermsAndAgreementContainer>
                        <Input type="checkbox" onClick={() => setAgreementAccepted(!agreementAccepted)} />
                        <div>
                            By checking here, I acknowledge and accept the
                            {' '}
                            <a href="https://www.voxity.ai/terms-of-service" target="_blank" rel="noreferrer">Affiliate Agreement</a>
                            {', '}
                            <a href="https://www.voxity.ai/terms-of-service" target="_blank" rel="noreferrer">terms of service</a>
                            {' '}
                            and
                            {' '}
                            <a href="https://www.voxity.ai/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>
                            .
                        </div>
                    </TermsAndAgreementContainer>
                </RenderIf>
                <RenderIf isTrue={confirmationResult}>
                    <TopContent>
                        <PageHeader
                            title="Great, you're on your way!"
                            description="We've sent a code to:"
                        />
                        <PhoneNumber>
                            {`*** *** ${phoneNumber?.phone?.slice(-4)}`}
                        </PhoneNumber>
                    </TopContent>
                    <CodeInput
                        label="Enter the 6-digit code"
                        labelAlignment="left"
                        value={code}
                        length={6}
                        onChange={setCode}
                        required
                        ref={codeInputRef}
                    />
                    <StyledButton
                        shaded
                        label="Verify and Proceed"
                        borderRadius="semi-square"
                        size="large"
                        variant="brand"
                        onClick={onLogin}
                        isLoading={isLoading}
                        disabled={!code}
                    />
                </RenderIf>
            </FormContainer>
            <Footer />
        </Container>
    );
};

export default Login;
