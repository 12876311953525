import { Field, isRequired } from '@rainbow-modules/forms';
import { FileSelector } from 'react-rainbow-components';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import UploadIcon from 'components/Icons/upload';
import PageHeader from 'components/PageHeader';
import { useFormState } from 'react-final-form';
import {
    StyledButton, ButtonLabel,
} from './styled';

const AskInsuranceCard = () => {
    const { values } = useFormState();
    const { file } = values;

    return (
        <>
            <PageHeader
                title="Let's verify the patient's insurance ⚕️"
                description="Please upload a clear photo of the patient's insurance card for accurate billing and verification."
            />
            <Field
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
                component={FileSelector}
                name="file"
                label="Upload Insurance Card"
                placeholder="Tap to upload or take a photo"
                borderRadius="square"
                uploadIcon={<UploadIcon />}
                labelAlignment="left"
                variant="multiline"
                validate={isRequired()}
                required
            />
            <StyledButton
                type="submit"
                borderRadius="semi-square"
                size="large"
                variant="brand"
                // onClick={handleNext}
                disabled={!file || file.length === 0}
                shaded
            >
                <ButtonLabel>
                    Next
                </ButtonLabel>
                <ArrowNarrowRight />
            </StyledButton>
        </>
    );
};

export default AskInsuranceCard;
