import styled, { css } from 'styled-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import { LinkButton } from '@rainbow-modules/misc';
import { Spinner } from 'react-rainbow-components';
import Input from 'components/Input';
import Button from 'components/Button';
import {
    BLACK, BRAND, BRAND_LIGHT, BRAND_BACKGROUND, WHITE,
    BRAND_LIGHTER, BUTTON_SHADOW,
} from '../../../constants';

export const StyledInput = styled(Input)`
    width: 100%;
`;

export const StyledLinkButton = styled(LinkButton)`
    width: 100%;
    margin-top: 1rem;
    border-radius: 0.375rem;
    letter-spacing: 0.02688rem;
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 3.2rem;
    box-shadow: 0px 2px 8px 0px ${BRAND_LIGHT};

    &[disabled] {
        background: ${BRAND};
        box-shadow: none;
    }

    svg {
        width: 1.8rem !important;
        height: 1.8rem !important;;
    }
`;

export const StyledButton = styled(Button)`
    margin-top: 1rem;
    width: 100%;
    color: ${WHITE};
    box-shadow: ${BUTTON_SHADOW};

    &[disabled] {
        background: ${BRAND_LIGHTER};
        color: ${WHITE};
        box-shadow: none; !important;
    }

    &:hover {
        color: ${WHITE};
    }
`;

export const ButtonLabel = styled.span`
    width: 100%;
    padding-left: 1.4rem;
`;

export const TopContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

// Picker Styles

interface StyledRadioItemProps {
    isHover?: boolean;
    isFocused?: boolean;
    isSelected?: boolean;
    disabled?: boolean;
}

export const StyledUniversalPicker = styled(UniversalPicker)`
    width: 100%;

    > div {
        gap: 0.5rem;

        > div {
            margin: 0;
        }
    }
`;

export const StyledRadioItem = styled.span<StyledRadioItemProps>`
    position: relative;
    width: 100%;
    padding: 1rem;
    background-color: white;
    border: solid 1px #dbdade;
    border-radius: 0.375rem;
    display: flex;

    ${(props) => props.isHover
        && `
            background-color: ${BRAND_BACKGROUND};    
            border: solid 1px ${BRAND};
            cursor: pointer;
    `};

    ${(props) => props.isFocused
        && `
            border: solid 1px ${BRAND};
            box-shadow: ${BRAND_LIGHT} 0px 0px 2px;
    `};

    ${(props) => props.isSelected
        && `
            border: solid 1px ${BRAND};
            box-shadow: ${BRAND_LIGHT} 0px 0px 2px;
    `};

    ${(props) => props.disabled
        && `
            border: solid 1px #dbdade;
            background-color: #dbdade;
            box-shadow: 0 0 0 0 transparent;
            cursor: not-allowed;
    `};
`;

export const StyledRadio = styled.span<StyledRadioItemProps>`
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    background: #fff;
    border: solid 2px #dbdade;
    border-radius: 20px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    flex-shrink: 0;

    &::after {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        top: 4px;
        left: 4px;
        background: #fff;
        border-radius: 20px;
        box-sizing: border-box;
    }

    ${(props) => props.isSelected && css`
            background: ${BRAND};
            border: 2px solid;
            border-color: ${BRAND};
            box-shadow: 0px 2px 4px 0px ${BRAND_LIGHT};

            &::after {
                background: #fff;
                box-sizing: border-box;
            }
    `};

    ${(props) => props.isFocused && css`
            background: ${BRAND};
            border: 2px solid;
            border-color: ${BRAND};
            box-shadow: 0px 2px 4px 0px ${BRAND_LIGHT};
    `};

    ${(props) => props.disabled && css`
            background-color: #fff;
            border-color: #dbdade;

            &::after {
                background: #fff;
                box-sizing: border-box;
            }
    `};
`;

export const StyledPickerOptionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-left: 0.85rem;
`;

export const StyledOptionLabel = styled.span`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3125rem;
    color: ${BLACK};
`;

export const StyledOptionDescription = styled.span`
    font-size: 0.85rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${BLACK};
`;

export const AddChildButton = styled.button`
    width: 100%;
    padding: 1rem;
    background-color: white;
    border: solid 1px #dbdade;
    border-radius: 0.375rem;
    display: flex;
    color: ${BLACK};
    gap: 0.75rem;
`;

export const StyledContent = styled.span`
    visibility: hidden;
`;

export const StyledSpinner = styled(Spinner)`
    visibility: visible;
`;
